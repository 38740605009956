<template>
  <el-card shadow="never">
    <el-row style="margin-bottom: 22px">
      <el-input v-model="queryInfo.assetSn" class="input" placeholder="请输入资产码" clearable>
      </el-input>
      <el-switch
              style="margin-right: 10px"
              v-model="queryInfo.disabled"
              active-text="查看已失效">
      </el-switch>
      <el-button type="primary" @click="currentChange(1)" :loading="loading">查询</el-button>
      <el-button type="primary" @click="dialogVisible = true">添加</el-button>
    </el-row>

    <el-table :data="ruleList" border style="width: 100%" stripe>
      <el-table-column align="center" prop="assetSn" label="资产码"></el-table-column>
      <el-table-column align="center" prop="createUserName" label="创建人" width="80px"></el-table-column>
      <el-table-column align="center" prop="createTime" label="创建时间" width="150px"></el-table-column>
      <el-table-column align="center" prop="updateUserName" label="失效人" width="80px"></el-table-column>
      <el-table-column align="center" prop="updateTime" label="失效时间" width="150px"></el-table-column>
      <el-table-column align="center" label="操作" width="80px">
        <template slot-scope="scope">
          <el-button @click="disableRule(scope.row)" type="danger" size="small" v-if="!scope.row.disabled">失效</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="page-box">
      <el-pagination background layout="total,prev, pager, next" :page-size="pageSize" :total="total" @current-change="currentChange" :current-page.sync="queryInfo.pageNo"></el-pagination>
    </div>
    <el-dialog title="添加数据" :visible.sync="dialogVisible" :close-on-click-modal="false" width="700px" :before-close="handleClose">
      <el-form :model="formData" :rules="formDataRules" label-width="200px" ref="addFormData">
        <el-form-item label="资产码：" prop="assetSn">
          <el-input v-model="formData.assetSn" placeholder="请输入资产码" clearable filterable style="width: 350px">
          </el-input>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" :loading="loading" @click="addRule">确定</el-button>
      </div>
    </el-dialog>
  </el-card>
</template>

<script>
  export default {
    name: "RuleExemption",
    data() {
      return {
        queryInfo: {
          assetSn:'',
          disabled: false,
          pageNo: 1
        },
        formDataRules: {
          assetSn: [
            {required: true, message: '请输入资产码', trigger: 'blur'}
          ],
        },
        formData: {
          assetSn:'',
        },
        total: 0,
        pageSize: 0,
        ruleList: [],
        dialogVisible: false,
        loading: false,
        exemption: false
      }
    },
    computed: {

    },
    created() {
      this.search();
    },
    methods: {
      async search() {
        this.loading = true;
        const {data: res} = await this.$axios.post('busRuleRetest/queryRule', this.queryInfo);
        this.loading = false;
        if (res.code !== 0) {
          return this.$message.error(res.message);
        }
        this.ruleList = res.data.records;
        this.total = res.data.total;
        this.pageSize = res.data.size;
      },
      currentChange(pageNo) {
        this.queryInfo.pageNo = pageNo;
        this.search()
      },
      disableRule(row) {
        this.$confirm('确定失效吗？', '提示', {
          cancelButtonText: '取消',
          confirmButtonText: '确定',
          type: 'warning',
        }).then(() => {
          this.$axios.post('busRuleRetest/disableRule', row.id).then(response => {
            if (response.data.code !== 0) {
              return this.$message.error(response.data.message);
            }
            this.$message.success("失效成功");
            this.search();
          })
        })
      },
      async addRule() {

        if (!this.formData.assetSn) {
          return this.$message.error('资产码不能为空');
        }

        this.loading = true
        const {data: res} = await this.$axios.post('busRuleRetest/addRule', this.formData);
        this.loading = false
        if (res.code !== 0) {
          return this.$message.error(res.message);
        }
        this.$message.success("添加成功");
        this.search();
        this.handleClose()
        this.formData.assetSn = ''
      },
      handleClose() {
        this.dialogVisible = false;
      }
    }
  }
</script>

<style scoped>
  .input {
    width: 200px;
    margin-right: 10px;
  }
</style>